import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';

import { Layout, SEO } from '../../components';
import { formatTimeUntilNow } from '../../lib';
import './style.scss';

const BlogPost = ({
  path,
  image,
  title,
  author,
  date,
  timeToRead,
  excerpt,
}) => (
  <div className="card">
    <div className="no-underline flex flex-col lg:flex-row relative">
      <Link className="lg:flex-1 mb-6 -mx-6 lg:-my-10 lg:mr-0" to={path}>
        <BackgroundImage
          Tag="div"
          style={{ backgroundSize: 'contain' }}
          className="blog-post-image"
          fluid={image.childImageSharp.fluid}
        >
          {' '}
        </BackgroundImage>
      </Link>

      <div className="lg:flex-1 lg:ml-4">
        <div className="flex mb-4">
          {author.image && (
            <Img
              alt={author.name}
              className="rounded-full mr-4"
              fixed={author.image.childImageSharp.fixed}
            />
          )}
          <div>
            <p className={`text-2xl text-primary-200 leading-tight`}>
              {author.name}
            </p>
            <p>
              {formatTimeUntilNow(date)}
              {` - ${timeToRead} min read`}
            </p>
          </div>
        </div>
        <h2 className="card-header">{title}</h2>
        <p>{excerpt}</p>
        <Link className="block mt-4" to={path}>
          Read more...
        </Link>
      </div>
    </div>
  </div>
);

const Blog = ({ data: { blogPosts } }) => {
  return (
    <Layout bodyClass="standard-layout page-blog">
      <SEO title="YOU are safe online's blog" url="blog" />

      <section className="header-section bg-black">
        <div className="container">
          <h1>Our Blog</h1>
          <p>
            A collection of knowledge to aid YOU and the ones you care about in
            your journey online.
          </p>
        </div>
      </section>

      <div className="container">
        <section className="body-section flex flex-col">
          {blogPosts.edges.map(
            ({
              node: {
                path,
                image,
                title,
                publishedDate,
                timeToRead,
                author,
                excerpt,
              },
            }) => (
              <BlogPost
                key={path}
                path={path}
                image={image}
                title={title}
                date={publishedDate}
                timeToRead={timeToRead}
                author={author?.frontmatter}
                excerpt={excerpt}
              />
            )
          )}
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery {
    blogPosts: allBlogPost(sort: { fields: [publishedDate], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          title
          path
          publishedDate
          author {
            frontmatter {
              name
              image {
                childImageSharp {
                  fixed(width: 60, quality: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
